/*Tables*/

.table-responsive {
	white-space: nowrap;
  }
  
  .table {
	td, th {
	  vertical-align: middle;
	  color: #6d757b;
	}
  }