/* Navbars */

.nav-search {
	input.form-control {
	  background-color: rgb(255 255 255 / 20%);
	  border: 1px solid rgb(255 255 255 / 45%);
	  border-top-left-radius: 30px;
	  border-bottom-left-radius: 30px;
  
	  &::placeholder {
		opacity: 0.5 !important;
		color: #fff !important;
	  }
  
	  &::-ms-input-placeholder {
		color: #fff !important;
	  }
	}
  
	button[type='submit'] {
	  background-color: rgb(255 255 255 / 20%);
	  border: 1px solid rgb(255 255 255 / 32%);
	  color: #fff;
	  border-top-right-radius: 30px;
	  border-bottom-right-radius: 30px;
	}
  }
  
  .nav-width {
	width: 340px !important;
  }
  
  .round-pagination.pagination .page-item {
	&:first-child .page-link {
	  border-top-left-radius: 30px;
	  border-bottom-left-radius: 30px;
	}
  
	&:last-child .page-link {
	  border-top-right-radius: 30px;
	  border-bottom-right-radius: 30px;
	}
  }