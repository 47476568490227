/*Header*/

.top-header {
	width: auto;
	height: 70px;
	line-height: 70px;
	background: #fff;
	position: fixed;
	top: 0;
	left: 260px;
	right: 0;
	z-index: 15;
	border-bottom: 1px solid #e4e4e4;
	box-shadow: none;
  }
  
  .sticky-top-header {
	background: #fff;
	border-bottom: 0px solid #e4e4e4;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }
  
  .toggle-btn {
	margin-right: 10px;
	font-size: 28px;
	color: #404142;
	width: 45px;
	height: 45px;
	line-height: 45px;
	background: transparent;
	text-align: center;
	border-radius: 50%;
	cursor: pointer;
	outline: none;
  
	&:hover {
	  color: #404142;
	}
  
	&:active {
	  color: #404142;
	  background: #f1f1f1;
	}
  }
  
  .logo-icon {
	width: 145px;
  }
  
  .logo-icon-2 {
	width: 35px;
	margin-left: 10px;
  }
  
  .logo-text {
	color: #404142;
	font-size: 25px;
	margin-bottom: 0;
	margin-left: 10px;
  }
  
  .top-header .navbar {
	padding: 0;
	background: transparent;
  }
  
  .left-topbar {
	width: auto;
	height: 70px;
	float: left;
	padding-left: 15px;
	background: transparent;
  }
  
  .right-topbar {
	width: auto;
	height: 70px;
	padding-right: 15px;
  
	.navbar-nav .nav-link {
	  padding: 0rem 1.4rem;
	  border-left: 1px solid #e4e4e4;
	  color: #404142;
	  height: 70px;
	  font-size: 25px;
	}
  }
  
  .search-bar {
	padding: 0rem 2.4rem;
  }
  
  .search-arrow-back, .search-btn-mobile {
	display: none;
  }
  
  .search-bar {
	.btn-search-back {
	  background: #f1f1f1;
	  border: 1px solid #d9d3d3;
	  padding: 0 20px;
	  border-radius: 2px;
	  height: calc(1.3em + .75rem + 2px);
  
	  i {
		font-size: 20px;
		color: #727171;
	  }
	}
  
	.btn-search {
	  background: #ffffff;
	  border: 1px solid #eaeaea;
	  padding: 0 25px;
	  border-radius: 0px;
	  height: calc(1.6em + .75rem + 2px);
  
	  i {
		font-size: 16px;
		color: #727171;
	  }
	}
  
	input {
	  height: calc(1.6em + .75rem + 2px);
	  border: 0;
	  border: 1px solid #eaeaea;
	  width: 100%;
	  border-radius: 2px;
	}
  }
  
  .dropdown-toggle-nocaret:after {
	display: none;
  }
  
  .user-box {
	line-height: 1.3;
	height: 70px;
  }
  
  .user-info {
	text-align: right;
	margin-right: 15px;
  
	.user-name {
	  font-size: 16px;
	  font-weight: 500;
	}
  
	.designattion {
	  font-size: 14px;
	  color: #a9a8a8;
	}
  }
  
  .user-img {
	width: 52px;
	height: 52px;
	border-radius: 50%;
	border: 1px solid #e5e5e5;
	padding: 3px;
  
	img {
	  border-radius: 10%;
	}
  }
  
  .msg-count {
	position: absolute;
	left: 36px;
	top: 19px;
	font-size: 11px;
	font-weight: 500;
	width: 17px;
	height: 17px;
	text-align: center;
	border-radius: 50%;
	z-index: 5;
	padding: 0;
	color: #fff;
	background-color: #673ab7;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .lang {
	i {
	  font-size: 18px !important;
	}
  
	span {
	  font-size: 18px !important;
	  padding-left: 6px;
	}
  }