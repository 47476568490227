

/*Background Colors*/

.bg-rose {

	background-color: #ff007c!important;

}

.bg-voilet {

	background-color: #7d00b5!important;

}

.bg-dribbble {

	background-color: #ea4c89!important;

}

.bg-facebook {

	background-color: #3b5998!important;

}

.bg-twitter {

	background-color: #55acee!important;

}

.bg-google {

	background-color: #e52d27!important;

}

.bg-linkedin {

	background-color: #0976b4!important;

}

.bg-youtube {

	background-color: #f8130b!important;

}

.bg-tumblr {

	background-color: #34526f!important;

}

.bg-vimeo {

	background-color: #86c9ef!important;

}

.bg-body {

	background-color: #f6f6f6!important;

}

.bg-gray {

	background-color: #dee1e6!important;

}

.bg-red-light {

	background-color: #fe6555!important;

}

.bg-primary-blue {

	background-color: #265ed7!important;

}

.bg-dark-blue {

	background-color: #0c1b7a!important;

}

.bg-shine-info {

	background-color: #54dee4!important;

}

.bg-wall {

	background-color: #00cd98!important;

}

.bg-sunset {

	background-color: #ff8b01!important;

}

.bg-light-primary {

	background-color: rgb(103 58 183 / 33%)!important;

}

.bg-light-success {

	background-color: #d6efdc!important;

}

.bg-light-danger {

	background-color: #ffc8da!important;

}

.bg-light-warning {

	background-color: #fff3d1!important;

}

.bg-light-info {

	background-color: #cde9ff!important;

}

.bg-light-purple {

	background-color: #dcdcff!important;

}

.bg-light-shineblue {

	background-color: #ccf7ff!important;

}

.bg-light-cyne {

	background-color: #cce8e8!important;

}

.bg-split-primary {

	background-color: #4f2894;

	border-color: #4f2894;

}

.bg-light-sinata {

	background-color: #f7cbff!important;

}

.bg-light-mehandi {

	background: #f0ffb8!important;

}

.bg-gradient-danger {

	background-image: linear-gradient(to left, #ff758c 0%, #ff7eb3 100%);

}

.bg-gradient-voilet {

	background-image: linear-gradient(to left, #a18cd1 0%, #fbc2eb 100%);

}

.bg-gradient-success {

	background-image: linear-gradient(to left, #05ac9d 0%, #20cfbf 100%);

}

.bg-gradient-mehandi {

	background-image: linear-gradient(to right, #dbe287, #cac531);

}
