/*Forms */

.form-control {
	box-shadow: none;
  }
  
  input::placeholder {
	opacity: 0.3 !important;
  }
  
  .form-check-label {
	font-size: 1rem;
  }
  
  .form-text {
	font-size: 13px;
  }
  
  .invalid-feedback {
	font-size: 100%;
	margin-top: 0.55rem;
  }
  
  .select2-container--bootstrap4 {
	.select2-selection--single, .select2-selection--multiple .select2-selection__rendered {
	  font-size: 1rem;
	}
  }
  
  .select2-sm .select2-container--bootstrap4 .select2-selection {
	height: calc(1.5em + .5rem + 2px);
	font-size: .875rem;
  }
  
  .select2-container--bootstrap4 .select2-dropdown {
	border-color: rgba(206, 212, 218, 0.55);
	border-radius: 0;
	box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
  }
  
  .g-r-left .select2-container--bootstrap4 .select2-selection {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
  }
  
  .g-r-right .select2-container--bootstrap4 .select2-selection {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
  }