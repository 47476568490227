/*Dark Theme*/

html.dark-theme {
	.switcher-wrapper {
	  background: #030b23;
	}
  
	body {
	  color: #ccced1;
	  background-color: #030b23;
	}
  
	.card {
	  background-color: #061238;
	  box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 32%);
	}
  
	.card-header {
	  border-bottom: 1px solid rgb(255 255 255 / 13%);
	}
  
	.card-footer {
	  border-top: 1px solid rgb(255 255 255 / 13%);
	}
  
	.text-secondary {
	  color: #868f96 !important;
	}
  
	hr {
	  border-top: 1px solid rgb(255 255 255 / 10%);
	}
  
	.top-header {
	  background-color: #061238;
	  border-bottom: 1px solid rgb(237 237 237 / 12%);
	}
  
	.toggle-btn {
	  color: #ccced1;
	}
  
	.right-topbar .navbar-nav .nav-link {
	  border-left: 1px solid rgb(255 255 255 / 12%);
	  color: #ccced1;
	}
  
	.search-bar input {
	  color: #ccced1;
	  background-color: #030b23;
	  border: 1px solid rgb(241 241 241 / 15%);
	  box-shadow: inset 0 1px 2px rgb(0 0 0 / 0%);
  
	  &::placeholder {
		color: #ccced1 !important;
		opacity: .5 !important;
  
		/* Firefox */
	  }
	}
  
	&::placeholder {
	  color: #ccced1 !important;
	  opacity: .5 !important;
  
	  /* Firefox */
	}
  
	.search-bar .btn-search {
	  background: #030b23;
	  border: 1px solid rgb(217 211 211 / 30%);
  
	  i {
		color: #ccced1;
	  }
	}
  
	.dropdown-menu {
	  color: #ccced1;
	  background-color: #030b23;
	  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 0.3);
	}
  
	.dropdown-item {
	  color: #acafb3;
	}
  
	.dropdown-menu .dropdown-item:hover {
	  background-color: #061238;
	}
  
	.dropdown-divider {
	  border-top: 1px solid rgb(255 255 255 / 12%);
	}
  
	.dropdown-lg {
	  .dropdown-menu .dropdown-item {
		border-bottom: 1px solid rgb(255 255 255 / 12%);
	  }
  
	  .msg-name {
		color: #fff;
	  }
  
	  .msg-info {
		color: #80868c;
	  }
  
	  .msg-time {
		color: #b1afaf;
	  }
  
	  .msg-footer {
		color: #bcc9d8;
	  }
	}
  
	.btn-white {
	  color: #ccced1;
	  background-color: #030b23;
	  border-color: rgb(206 212 218 / 45%);
	  border-top: 1px solid #63686f;
	  border-left: 1px solid #63686f;
	}
  
	.user-img {
	  border: 1px solid rgb(255 255 255 / 28%);
	}
  
	.table {
	  color: #ccced1;
  
	  td, th {
		color: #cbcfd2;
		border-top: 1px solid rgb(255 255 255 / 12%);
	  }
  
	  thead th {
		border-bottom: 2px solid rgb(255 255 255 / 12%);
	  }
	}
  
	.border {
	  border: 1px solid rgb(255 255 255 / 12%) !important;
	}
  
	.top-header .navbar {
	  .dropdown-menu::after {
		background: #030b23;
		border-top: 1px solid #5c636b;
		border-left: 1px solid #5c636b;
	  }
  
	  .dropdown-lg .dropdown-menu::after {
		background: #673ab7;
		border-top: 1px solid #673ab7;
		border-left: 1px solid #673ab7;
	  }
	}
  
	.highcharts-background {
	  fill: rgb(255 255 255 / 0%);
	}
  
	.highcharts-title {
	  fill: #ccced1 !important;
	  font-size: 1.5em;
	}
  
	.highcharts-root text {
	  fill: #8f949a;
	}
  
	.highcharts-grid-line {
	  fill: none;
	  stroke: rgb(255 255 255 / 12%);
	}
  
	.sidebar-wrapper {
	  background: #061238;
	  border-right: 1px solid rgb(255 255 255 / 12%);
  
	  .sidebar-header {
		background: #061238;
		border-right: 1px solid rgb(255 255 255 / 12%);
		border-bottom: 1px solid rgb(255 255 255 / 12%);
  
		.logo-text {
		  color: #e7e9ec;
		}
  
		.toggle-btn {
		  color: #e7e9ec;
  
		  &:hover {
			color: #fff;
		  }
  
		  &:active {
			color: #fff;
			background: rgb(255 255 255 / 25%);
		  }
		}
	  }
	}
  
	.sidebar .navigation li {
	  &.open > a {
		color: #fff;
		background: rgb(255 255 255 / 18%);
	  }
  
	  > a {
		color: #9ea4aa;
	  }
  
	  &:hover > a, &.active > a {
		color: #fff;
		background: rgb(255 255 255 / 18%);
	  }
  
	  ul {
		border: 1px solid #ffffff00;
		background: rgb(255 255 255 / 8%);
  
		li {
		  &:hover > a, &.active > a {
			color: #fff;
			background: rgb(255 255 255 / 18%);
		  }
		}
	  }
	}
  
	.simplebar-scrollbar:before {
	  background: rgba(255, 255, 255, 0.4);
	}
  
	.footer {
	  background: #030b23;
	  border-top: 1px solid rgb(255 255 255 / 12%);
	}
  
	.btn-light-warning {
	  color: #ff9305;
	}
  
	.btn-light-dark {
	  color: #757c83;
	}
  
	.modal-content {
	  background-color: #061238;
	}
  
	.modal-header {
	  border-bottom: 1px solid rgb(222 226 230 / 16%);
	}
  
	.modal-footer {
	  border-top: 1px solid rgb(222 226 230 / 16%);
	}
  
	.email-wrapper {
	  background: #061238;
	  box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 24%);
	}
  
	.email-sidebar {
	  background: #061238;
	  border-right: 1px solid rgb(255 255 255 / 0.12);
	}
  
	.email-sidebar-header {
	  background: #061238;
	  border-bottom: 1px solid rgb(255 255 255 / 0.12);
	  border-right: 0px solid rgb(255 255 255 / 12%);
	}
  
	.email-navigation {
	  border-bottom: 1px solid rgb(255 255 255 / 13%);
  
	  a.list-group-item {
		color: #ccced1;
		background-color: #061238;
		border-bottom: 1px solid rgb(0 0 0 / 0%);
  
		&:hover {
		  background-color: rgb(0 123 255 / 15%);
		}
	  }
	}
  
	.email-meeting a.list-group-item {
	  color: #ccced1;
	  background-color: #061238;
	  border-bottom: 1px solid rgb(0 0 0 / 0%);
  
	  &:hover {
		background-color: rgb(0 123 255 / 15%);
	  }
	}
  
	.list-group-item {
	  background-color: #061238;
	  border: 1px solid rgb(255 255 255 / 13%);
	}
  
	.email-navigation a.list-group-item.active {
	  color: #673AB7;
	  font-weight: 600;
	  background-color: rgb(103 58 183 / 21%);
	}
  
	.list-group-flush > .list-group-item {
	  border-width: 0 0 1px;
	}
  
	.border-top {
	  border-top: 1px solid rgb(255 255 255 / 13%) !important;
	}
  
	.email-header {
	  background: #061238;
	  border-bottom: 1px solid rgb(255 255 255 / 13%);
	}
  
	.email-content {
	  background: #061238;
	}
  
	.email-list div.email-message {
	  background: #061238;
	  border-bottom: 1px solid rgb(255 255 255 / 12%);
	  color: #ccced1;
  
	  &:hover {
		transition: all .2s ease-out;
		background: #434a50;
	  }
	}
  
	.bg-body {
	  background-color: #030b23 !important;
	}
  
	.email-time, .chat-time {
	  font-size: 13px;
	  color: #8c969e;
	}
  
	.widgets-icons {
	  color: #030b23;
	}
  
	.invoice {
	  background-color: #061238;
	}
  
	.chat-wrapper {
	  background: #061238;
	  box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 22%);
	}
  
	.chat-sidebar {
	  background: #061238;
	  border-right: 1px solid rgb(255 255 255 / 13%);
	}
  
	.chat-sidebar-header {
	  background: #061238;
	  border-bottom: 1px solid rgb(255 255 255 / 13%);
	  border-right: 0px solid rgb(255 255 255 / 13%);
	}
  
	.chat-header {
	  background: #061238;
	  border-bottom: 1px solid rgb(255 255 255 / 13%);
	}
  
	.chat-tab-menu li a.nav-link {
	  color: #c3c5c8;
	}
  
	.chat-title {
	  color: #ccced1;
	}
  
	.chat-msg {
	  font-size: 14px;
	  color: #848992;
	}
  
	.chat-top-header-menu a {
	  color: #c3c5c8;
	  background-color: #030b23;
	  border: 1px solid rgb(255 255 255 / 15%);
	}
  
	.chat-content-leftside .chat-left-msg {
	  background-color: #030b23;
	}
  
	.chat-content-rightside .chat-right-msg {
	  background-color: #41484e;
	}
  
	.chat-footer {
	  background: #061238;
	  border-top: 1px solid rgb(255 255 255 / 13%);
	}
  
	.chat-footer-menu a {
	  color: #c3c5c8;
	  background-color: #030b23;
	  border: 1px solid rgb(255 255 255 / 15%);
	}
  
	.fm-menu .list-group a {
	  color: #c3c5c8;
	}
  
	.fm-icon-box {
	  background: #030b23;
	  color: #ffffff;
	}
  
	.contacts-social a {
	  background: #030b23;
	}
  
	.invoice {
	  table {
		td, th {
		  background: rgb(255 255 255 / 12%);
		  border-bottom: 1px solid rgb(255 255 255 / 12%);
		}
  
		.no {
		  color: #fff;
		  background: #007bff;
		}
	  }
  
	  main .notices {
		background: #030b23;
	  }
  
	  footer {
		color: #ccced1;
		border-top: 1px solid rgb(255 255 255 / 12%);
	  }
	}
  
	.fc-theme-standard {
	  td, th {
		border: 1px solid rgb(255 255 255 / 0.12);
	  }
  
	  .fc-scrollgrid {
		border: 1px solid rgb(255 255 255 / 12%);
	  }
	}
  
	.fc {
	  .fc-col-header-cell-cushion, .fc-daygrid-day-number {
		color: #ffffff;
	  }
	}
  
	.breadcrumb {
	  background-color: #030b23;
	}
  
	.breadcrumb-item.active {
	  color: #8b969f;
	}
  
	.breadcrumb-title {
	  border-right: 1.5px solid rgb(255 255 255 / 18%);
	}
  
	.btn-outline-secondary {
	  color: #c5cace;
	  border-color: #c2c9ce;
	}
  
	.btn-light {
	  color: #ffffff;
	  background-color: rgb(255 255 255 / 15%);
	  border-color: rgb(255 255 255 / 22%);
  
	  &:hover {
		color: #ffffff;
		background-color: rgb(255 255 255 / 25%);
		border-color: rgb(255 255 255 / 32%);
	  }
	}
  
	.btn-dark {
	  color: #fff;
	  background-color: #181e22;
	  border-color: #181e22;
  
	  &:hover {
		color: #fff;
		background-color: #13181b;
		border-color: #13181b;
	  }
	}
  
	.btn-outline-dark {
	  color: #ffffff;
	  border-color: #181e22;
	}
  
	.page-link {
	  color: #b2b6ba;
	  background-color: #030b23;
	  border: 1px solid rgb(255 255 255 / 0.12);
	}
  
	.page-item.disabled .page-link {
	  color: #b0b6bc;
	  background-color: rgb(255 255 255 / 28%);
	  border-color: rgb(222 226 230 / 14%);
	}
  
	.progress {
	  background-color: rgb(255 255 255 / 14%);
	}
  
	.chip {
	  color: rgb(198 200 203);
	  background-color: #030b23;
	  border: 1px solid rgb(255 255 255 / 15%);
	}
  
	.bg-light {
	  background-color: rgb(255 255 255 / 15%) !important;
	}
  
	ul.icons li {
	  box-shadow: 0 1px 5px #13171a;
	  background: #030b23;
  
	  i {
		color: #ffffff;
	  }
	}
  
	.icon-box {
	  background: #061238;
	  box-shadow: 0 1px 5px #161a1e;
	}
  
	.pByfF i {
	  color: #ffffff;
	}
  
	.form-control {
	  color: #c0c8d1;
	  background-color: #030b23;
	  border: 1px solid rgb(255 255 255 / 34%);
	}
  
	.bootstrap-tagsinput {
	  background-color: #030b23;
	  border: 1px solid rgb(255 255 255 / 35%);
	  box-shadow: inset 0 1px 1px rgb(0 0 0 / 37%);
	  color: #e5e0e0;
	}
  
	.input-group-text {
	  color: #d1d7de;
	  background-color: #3e464c;
	  border: 1px solid rgb(255 255 255 / 35%);
	}
  
	.table-bordered {
	  td, th {
		border: 1px solid rgb(255 255 255 / 12%);
	  }
	}
  
	.table-dark {
	  background-color: #1d2126;
	}
  
	.table .thead-light th {
	  background-color: rgb(255 255 255 / 20%);
	  border-color: #ffffff;
	}
  
	.table-bordered {
	  border: 1px solid rgb(255 255 255 / 12%);
	}
  
	.login-card {
	  border-right: 1px solid rgb(255 255 255 / 12%) !important;
	}
  
	.border-bottom {
	  border-bottom: 1px solid rgb(255 255 255 / 14%) !important;
	}
  
	.navbar-light .navbar-nav {
	  .nav-link {
		color: rgb(255 255 255 / 50%);
	  }
  
	  .active > .nav-link {
		color: rgb(255 255 255 / 90%);
	  }
  
	  .nav-link {
		&.active, &.show {
		  color: rgb(255 255 255 / 90%);
		}
	  }
  
	  .show > .nav-link {
		color: rgb(255 255 255 / 90%);
	  }
	}
  
	.bg-light-purple {
	  background-color: rgb(130 128 255 / 32%) !important;
	}
  
	.bg-light-primary {
	  background-color: rgb(103 58 183 / 33%) !important;
	}
  
	.bg-light-danger {
	  background-color: rgb(220 53 69 / 33%) !important;
	}
  
	.bg-light-shineblue {
	  background-color: rgb(1 215 255 / 33%) !important;
	}
  
	.bg-light-cyne {
	  background-color: rgb(0 139 139 / 32%) !important;
	}
  
	.bg-light-warning {
	  background-color: rgb(255 193 7 / 32%) !important;
	}
  
	.bg-light-success {
	  background-color: rgb(40 167 69 / 33%) !important;
	}
  
	.bg-light-info {
	  background-color: rgb(25 143 237 / 22%) !important;
	}
  
	.bg-light-sinata {
	  background-color: rgb(165 43 187 / 33%) !important;
	}
  
	.bg-light-mehandi {
	  background: rgb(131 152 56 / 32%) !important;
	}
  
	.logo-white {
	  display: block;
	}
  
	.logo-dark {
	  display: none;
	}
  
	.email-toggle-btn {
	  color: #c9cbce;
	  background-color: #ffffff00;
	}
  
	.chat-toggle-btn {
	  color: #c9cbce;
	  background-color: #ffffff00;
	  border: 0px solid rgb(0 0 0 / 15%);
	}
  
	.apexcharts-pie-label, .apexcharts-datalabels, .apexcharts-datalabel, .apexcharts-datalabel-label, .apexcharts-datalabel-value {
	  fill: #fff;
	}
  }
  
  /*email box*/
  
  /*chat box*/
  
  /* components */
  
  /* background colors */
  
  /*Responsive*/
  
  @media screen and (max-width: 1024px) {
	html.dark-theme {
	  .wrapper.toggled .overlay, .chat-toggled .overlay, .email-toggled .overlay {
		background: #fff;
		opacity: 0.4;
	  }
  
	  .full-search-bar {
		background: #061238;
	  }
  
	  .search-bar .btn-search-back {
		background: rgb(3 11 35 / 0%);
		border: 1px solid rgb(217 211 211 / 0%);
		height: calc(1.6em + .75rem + 2px);
  
		i {
		  color: #ccced1;
		}
	  }
	}
  }
  
  @media screen and (max-width: 767px) {
	html.dark-theme .right-topbar .navbar-nav .nav-link {
	  border-left: 0px solid #ededed;
	}
  }