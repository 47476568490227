/*Buttons*/

.btn {
	text-transform: capitalize;
  
	i {
	  vertical-align: middle;
	  font-size: 1.3rem;
	  margin-top: -1em;
	  margin-bottom: -1em;
	}
  }
  
  .btn-white {
	color: #212529;
	background-color: #ffffff;
	border-color: #ced4da;
  }
  
  .btn-light-primary {
	color: #673ab7;
	background-color: rgb(103 58 183 / 30%);
	border-color: rgb(103 58 183 / 30%);
  }
  
  .btn-light-success {
	color: #32ab13;
	background-color: rgb(50 171 19 / 30%);
	border-color: rgb(50 171 19 / 30%);
  }
  
  .btn-light-danger {
	color: #f02769;
	background-color: rgb(240 39 105 / 30%);
	border-color: rgb(240 39 105 / 30%);
  }
  
  .btn-light-info {
	color: #198fed;
	background-color: rgb(25 143 237 / 30%);
	border-color: rgb(25 143 237 / 30%);
  }
  
  .btn-light-warning {
	color: #212529;
	background-color: rgb(255 193 7 / 30%);
	border-color: rgb(255 193 7 / 30%);
  }
  
  .btn-light-secondary {
	color: #5a7684;
	background-color: rgb(90 118 132 / 30%);
	border-color: rgb(90 118 132 / 30%);
  }
  
  .btn-light-dark {
	color: #343a40;
	background-color: rgb(52 58 64 / 30%);
	border-color: rgb(52 58 64 / 30%);
  }
  
  .btn-facebook {
	box-shadow: 0 2px 2px 0 rgba(59, 89, 152, 0.14), 0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12);
	background-color: #3b5998;
	border-color: #3b5998;
	color: #fff;
  
	&:hover {
	  color: #fff;
	}
  
	&:focus {
	  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
	}
  }
  
  .btn-twitter {
	box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14), 0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
	background-color: #55acee;
	border-color: #55acee;
	color: #fff;
  
	&:hover {
	  color: #fff;
	}
  
	&:focus {
	  box-shadow: 0 0 0 0.2rem rgba(85, 172, 238, 0.5);
	}
  }
  
  .btn-linkedin {
	box-shadow: 0 2px 2px 0 rgba(9, 118, 180, 0.14), 0 3px 1px -2px rgba(9, 118, 180, 0.2), 0 1px 5px 0 rgba(9, 118, 180, 0.12);
	background-color: #0976b4;
	border-color: #0976b4;
	color: #fff;
  
	&:hover {
	  color: #fff;
	}
  
	&:focus {
	  box-shadow: 0 0 0 0.2rem rgba(9, 118, 180, 0.5);
	}
  }
  
  .btn-youtube {
	box-shadow: 0 2px 2px 0 rgba(229, 45, 39, 0.14), 0 3px 1px -2px rgba(229, 45, 39, 0.2), 0 1px 5px 0 rgba(229, 45, 39, 0.12);
	background-color: #e52d27;
	border-color: #e52d27;
	color: #fff;
  
	&:hover {
	  color: #fff;
	}
  
	&:focus {
	  box-shadow: 0 0 0 0.2rem rgba(229, 45, 39, 0.5);
	}
  }
  
  .btn-github {
	box-shadow: 0 2px 2px 0 rgba(51, 51, 51, 0.14), 0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12);
	background-color: #333333;
	border-color: #333333;
	color: #fff;
  
	&:hover {
	  color: #fff;
	}
  
	&:focus {
	  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
	}
  }
  
  .btn-skype {
	box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14), 0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
	background-color: #00aff0;
	border-color: #00aff0;
	color: #fff;
  
	&:hover {
	  color: #fff;
	}
  
	&:focus {
	  box-shadow: 0 0 0 0.2rem rgba(0, 175, 240, 0.5);
	}
  }
  
  .btn-pinterest {
	box-shadow: 0 2px 2px 0 rgba(204, 33, 39, 0.14), 0 3px 1px -2px rgba(204, 33, 39, 0.2), 0 1px 5px 0 rgba(204, 33, 39, 0.12);
	background-color: #cc2127;
	border-color: #cc2127;
	color: #fff;
  
	&:hover {
	  color: #fff;
	}
  
	&:focus {
	  box-shadow: 0 0 0 0.2rem rgba(204, 33, 39, 0.5);
	}
  }
  
  .btn-dribbble {
	box-shadow: 0 2px 2px 0 rgba(234, 76, 137, 0.14), 0 3px 1px -2px rgba(234, 76, 137, 0.2), 0 1px 5px 0 rgba(234, 76, 137, 0.12);
	background-color: #ea4c89;
	border-color: #ea4c89;
	color: #fff;
  
	&:hover {
	  color: #fff;
	}
  
	&:focus {
	  box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
	}
  }
  
  .btn-group-round button {
	&:first-child {
	  border-top-left-radius: 10px;
	  border-bottom-left-radius: 10px;
	}
  
	&:last-child {
	  border-top-right-radius: 10px;
	  border-bottom-right-radius: 10px;
	}
  }