
  /*File Manager*/
  
  .fm-wrapper {
	position: relative;
  }
  
  .fm-left-sidebar {
	background: #ffffff;
	width: 220px;
	height: 100%;
	position: fixed;
	padding: 10px;
	overflow-x: hidden;
	overflow-y: scroll;
  }
  
  .fm-body {
	background: white;
	width: 100%;
	height: 100%;
  }
  
  .fm-menu .list-group a {
	font-size: 16px;
	color: #5f5f5f;
	display: flex;
	align-items: center;
  
	i {
	  font-size: 23px;
	}
  
	&:hover {
	  background: #673ab7;
	  color: #ffffff;
	  transition: all .2s ease-out;
	}
  }
  
  .fm-icon-box {
	font-size: 32px;
	background: #ffffff;
	width: 52px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: .25rem;
  }
  
  .fm-file-box {
	font-size: 25px;
	background: #e9ecef;
	width: 44px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: .25rem;
  }
  
  .user-groups img {
	margin-left: -14px;
	border: 1px solid #e4e4e4;
	padding: 2px;
	cursor: pointer;
  }
  
  .user-plus {
	width: 33px;
	height: 33px;
	margin-left: -14px;
	line-height: 33px;
	background: #ffffff;
	border-radius: 50%;
	text-align: center;
	font-size: 22px;
	cursor: pointer;
	border: 1px dotted #a9b2bb;
	color: #404142;
  }