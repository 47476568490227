/*Extra Css*/

.login-separater span {
	position: relative;
	top: 26px;
	margin-top: -10px;
	background: #ffffff;
	padding: 5px;
	font-size: 12px;
	color: #cbcbcb;
  }
  
  .login-img {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
  }
  
  .contacts-social a {
	font-size: 16px;
	width: 26px;
	height: 26px;
	line-height: 26px;
	background: #f7f5f5;
	text-align: center;
	border-radius: 0.25rem;
  }
  
  .iconFilterTypes {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
  
	.tab {
	  &.active, &:hover {
		font-weight: 700;
		color: #756af8;
	  }
  
	  color: #000;
	  font-weight: 700;
	  display: inline-block;
	  cursor: pointer;
	}
  }
  
  .list {
	position: relative;
  }
  
  ul.icons {
	list-style: none;
  
	li {
	  position: relative;
	  cursor: pointer;
	  padding: 10px;
	  width: 204px;
	  float: left;
	  box-shadow: 0 1px 5px #e3e7eb;
	  margin: 13px;
	  -webkit-border-radius: 4px;
	  -moz-border-radius: 4px;
	  border-radius: 4px;
	  background: #f8f9fb;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	  -webkit-transition: all .1s linear;
	  -moz-transition: all .1s linear;
	  -o-transition: all .1s linear;
	  transition: all .1s linear;
  
	  i {
		color: #673ab7;
		width: 30px;
		height: 30px;
		font-size: 30px;
		text-align: center;
		display: block;
		float: left;
		line-height: 34px;
		margin-left: 5px;
	  }
  
	  &:hover {
		background: #eef1f7;
	  }
  
	  span {
		text-indent: 15px;
		display: block;
		line-height: 32px;
		overflow: hidden;
		text-overflow: ellipsis;
	  }
	}
  }
  
  .pByfF {
	animation-fill-mode: forwards;
	cursor: pointer;
	display: inline-block;
	flex: 0 0 10%;
	transition: all 0.15s ease 0s;
	border-radius: 8px;
	overflow: hidden;
	outline: none !important;
	background: #f8f9fb;
	box-shadow: 0 1px 5px #e3e7eb;
	width: 122px;
	height: 122px;
	margin: 7px 7px;
	padding: 10px 14px;
  
	&.active .icon-box-inner {
	  background: rgb(238, 238, 238);
	}
  
	.icon-box-inner {
	  font-weight: 700;
	  position: relative;
	  font-size: 16px;
	  line-height: 35px;
	  text-align: center;
	  pointer-events: none;
	  margin: 1px;
	  border-radius: 8px;
	  background: transparent;
	  padding: 18px 10px;
	}
  
	i {
	  color: #673ab7;
	  font-size: 36px;
	}
  
	.icon-box-inner .icon-box-name {
	  font-size: 12px;
	  text-transform: capitalize;
	  user-select: none;
	  color: #a5a5a5;
	  font-weight: 400;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	  line-height: 15px;
	  overflow: hidden;
	  margin: 5px auto 2px;
	}
  
	&:hover {
	  background: #eaecf1;
	}
  }
  
  .media-icons {
	font-size: 54px;
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	color: #fff;
	border-radius: 50%;
  }
  
  .dashboard-icons {
	width: 55px;
	height: 55px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ededed;
	font-size: 42px;
	border-radius: 10px;
  }
  
  .widgets-icons {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ededed;
	font-size: 26px;
	border-radius: 10px;
  }
  
  .widgets-social {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ededed;
	font-size: 22px;
	border-radius: 10px;
  }
  
  .icon-box {
	padding: 10px 14px;
	background: #ffffff;
	box-shadow: 0 1px 5px #e3e7eb;
  }
  
  .icon-symbol i {
	font-size: 30px;
	color: #673ab7;
  }
  
  .icon-name {}
  
  .p-10 {
	padding: 10px;
  }
  
  .p-15 {
	padding: 15px;
  }
  
  .radius-10 {
	border-radius: 10px;
  }
  
  .radius-15 {
	border-radius: 15px;
  }
  
  .radius-30 {
	border-radius: 30px;
  }
  
  .row.row-group > div {
	border-right: 1px solid rgb(0 0 0 / 12%);
  
	&:last-child {
	  border-right: none;
	}
  }
  
  .accordion .card-header {
	&:after {
	  font-family: 'LineIcons';
	  content: "\eb2c";
	  float: right;
	  color: #673ab7;
	}
  
	&.collapsed:after {
	  /* symbol for "collapsed" panels */
  
	  content: "\eb53";
	}
  }
  
  .color-acordians .accordion .card-header:after {
	color: #fff;
  }
  
  .cursor-pointer {
	cursor: pointer;
  }
  
  .chart-container1 {
	position: relative;
	height: 340px;
  }
  
  .chart-container2 {
	position: relative;
	height: 400px;
  }
  
  .chart-container3 {
	position: relative;
	width: 200px;
	height: 80px;
	right: -1.25rem;
	top: -1.0rem;
  }
  
  .gmaps, .gmaps-panaroma {
	height: 400px;
	background: #eeeeee;
	border-radius: 3px;
  }
  
  #location-map {
	width: 100%;
	height: 220px;
  }
  
  #geographic-map {
	width: 100%;
	height: 340px;
  }
  
  .product-img {
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f3f3f3;
	border-radius: 10px;
  }
  
  .vertical-align-middle {
	vertical-align: middle;
  }
  
  .vertical-separater {
	width: 1px;
	background: #dadada;
	height: 50px;
	margin: auto;
  }