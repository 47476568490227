/* You can add global styles to this file, and also import other style files */
.ngb-toasts {
    margin: .5em;
    position: fixed;
    right: 15% !important;
    top: 2%;
    z-index: 1200;
}

table.dataTable td, table.dataTable th {
    font-size: 0.8em !important;
}

table.dataTable td.action {
    font-size: 1.5em !important;
}

table.table td.action {
    font-size: 1.5em !important;
}