
/*Text Colors*/

.text-facebook {

	color: #3b5998!important;

}

.text-twitter {

	color: #55acee!important;

}

.text-youtube {

	color: #f8130b!important;

}

.text-linkedin {

	color: #0a66c2!important;

}

.text-skype {

	color: #00aff0!important;

}

.text-purple {

	color: #8280ff!important;

}

.text-shineblue {

	color: #01d7ff!important;

}

.text-cyne {

	color: #008b8b!important;

}

.text-primary-blue {

	color: #265ed7!important;

}

.text-dark-blue {

	color: #0c1b7a!important;

}

.text-shine-info {

	color: #54dee4!important;

}

.text-grey {

	color: #dee1e6!important;

}

.text-red {

	color: #ff392b!important;

}

.text-sinata {

	color: #a52bbb!important;

}

.text-mehandi {

	color: #839838!important;

}

.border-lg-top-primary {

	border-top: 4px solid #673ab7;

}

.border-lg-top-info {

	border-top: 4px solid #198fed;

}

.border-lg-top-danger {

	border-top: 4px solid #f02769;

}

.icon-color-1 {

	color: #ff3030;

}

.icon-color-2 {

	color: #ff007c;

}

.icon-color-3 {

	color: #4CAF50;

}

.icon-color-4 {

	color: #ff8b01;

}

.icon-color-5 {

	color: #a400ed;

}

.icon-color-6 {

	color: #009688;

}

.icon-color-7 {

	color: #03A9F4;

}

.icon-color-8 {

	color: #c37458;

}

.icon-color-9 {

	color: #265ed7;

}

.icon-color-10 {

	color: #30d4e4;

}

.icon-color-11 {

	color: #ca9805;

}

.icon-color-12 {

	color: #a24f30;

}
