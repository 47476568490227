/*Pricing Table */

.lis-bg-light {
	background-color: #00000005;
  }
  
  .lis-light {
	color: #707070;
  }
  
  .price-table {
	-webkit-transition: 0.3s ease;
  }
  
  .lis-brd-light {
	border-color: #dadada !important;
  }
  
  .lis-rounded-top {
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
  }
  
  .lis-bg-light {
	background-color: #f7f7f7;
  }
  
  .lis-latter-spacing-2 {
	letter-spacing: 2px;
  }
  
  .lis-font-weight-500 {
	font-weight: 500;
  }
  
  .price-table {
	sup {
	  top: -1.5em;
	  font-size: 1.25rem;
	}
  
	small {
	  font-size: 1.25rem;
	  font-size: 1.25rem;
	}
  }
  
  sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
  }
  
  .lis-brd-light {
	border-color: #dadada !important;
  }
  
  .lis-line-height-3 {
	line-height: 3 !important;
  }
  
  .list-unstyled {
	padding-left: 0;
	list-style: none;
  }
  
  .lis-line-height-3 {
	line-height: 3 !important;
  }
  
  .lis-rounded-circle-50 {
	border-radius: 50px;
  }
  
  .lis-bg-primary {
	background-color: #673ab7;
  }
  
  .price-table {
	&.active {
	  transform: scale(1.045);
	  -webkit-transform: scale(1.045);
	}
  
	-webkit-transition: 0.3s ease;
  }
  
  .lis-rounded-bottom {
	border-bottom-right-radius: 30px;
	border-bottom-left-radius: 30px;
  }