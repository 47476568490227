.card {
	box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
	margin-bottom: 30px;
  }
  
  .card-header {
	font-size: 16px;
	text-transform: capitalize;
  }
  
  .card-group {
	margin-bottom: 30px;
	box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
  
	.card {
	  box-shadow: none;
	}
  }
  
  .metismenu-card .card-header {
	cursor: pointer;
  }
  
  .card-deck .card {
	margin-bottom: 30px !important;
  }