.font-small {
	font-size: 50%;
  }
  
  .font-13 {
	font-size: 13px;
  }
  
  .font-14 {
	font-size: 14px;
  }
  
  .font-20 {
	font-size: 20px;
  }
  
  .font-24 {
	font-size: 24px;
  }
  
  .font-30 {
	font-size: 30px;
  }
  
  .font-35 {
	font-size: 35px;
  }
  
  .font-40 {
	font-size: 40px;
  }
  
  .font-60 {
	font-size: 60px;
  }