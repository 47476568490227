/*Authentication*/

.section-authentication-login {
	height: 100vh;
	padding-top: 1.0rem;
	padding-bottom: 1rem;
  }
  
  .section-authentication-register {
	height: 100%;
	padding: 1.5rem;
  }
  
  .authentication-forgot {
	height: 100vh;
	padding: 0 1rem;
  }
  
  .forgot-box {
	width: 27rem;
	border-radius: 30px;
  }
  
  .authentication-reset-password, .authentication-lock-screen, .error-404 {
	height: 100vh;
	padding: 0 1rem;
  }
  
  .login-card {
	border-right: 1px solid #dee2e6 !important;
  }
  
  .error-social a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 18px;
	color: #fff;
	text-align: center;
	border-radius: 50%;
	margin: 5px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  
  .profile-social a {
	display: inline-block;
	width: 33px;
	height: 33px;
	line-height: 33px;
	font-size: 15px;
	color: #fff;
	text-align: center;
	border-radius: 50%;
	margin: 2px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  
  .bg-forgot {
	background-image: url(../../../images/login-images/bg-forgot-password.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
  }
  
  .bg-lock-screen {
	background-image: url(../../../images/login-images/bg-lock-screen.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
  }
  
  .bg-login {
	background-image: url(../../../images/login-images/bg-login-img.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
  }
  
  .bg-register, .bg-coming-soon {
	background-image: url(../../../images/login-images/bg-register-img.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
  }