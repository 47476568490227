.dropdown-menu {
	line-height: initial;
	font-size: 14px;
	border-radius: 15px;
  }
  
  .top-header .navbar {
	.dropdown-menu {
	  -webkit-animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 animdropdown;
	  animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 animdropdown;
	}
  
	.dropdown-lg .dropdown-menu::after {
	  content: '';
	  width: 13px;
	  height: 13px;
	  background: #673ab7;
	  position: absolute;
	  top: -6px;
	  right: 28px;
	  transform: rotate(45deg);
	  border-top: 1px solid #673ab7;
	  border-left: 1px solid #673ab7;
	}
  
	.dropdown-menu::after {
	  content: '';
	  width: 13px;
	  height: 13px;
	  background: #ffff;
	  position: absolute;
	  top: -6px;
	  right: 28px;
	  transform: rotate(45deg);
	  border-top: 1px solid #ddd;
	  border-left: 1px solid #ddd;
	}
  }
  
  @-webkit-keyframes animdropdown {
	from {
	  -webkit-transform: translate3d(0, 6px, 0);
	  transform: translate3d(0, 6px, 0);
	  opacity: 0;
	}
  
	to {
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	  opacity: 1;
	}
  }
  
  @keyframes animdropdown {
	from {
	  -webkit-transform: translate3d(0, 6px, 0);
	  transform: translate3d(0, 6px, 0);
	  opacity: 0;
	}
  
	to {
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	  opacity: 1;
	}
  }
  
  .dropdown-lg {
	position: relative;
  
	.dropdown-menu {
	  width: 380px;
	  padding: 0px;
	  margin: 0;
	  border: 0;
  
	  .dropdown-item {
		padding: .72rem 1.2rem;
		border-bottom: 1px solid #ededed;
	  }
	}
  
	.user-online {
	  position: relative;
  
	  &:after {
		content: '';
		position: absolute;
		bottom: 1px;
		right: 17px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		box-shadow: 0 0 0 2px #fff;
		background: #16e15e;
	  }
	}
  
	.msg-avatar {
	  width: 45px;
	  height: 45px;
	  border-radius: 50%;
	  margin-right: 15px;
	}
  
	.msg-name {
	  font-size: 14px;
	  margin-bottom: 0;
	}
  
	.msg-info {
	  font-size: 13px;
	  margin-bottom: 0;
	}
  
	.msg-time {
	  font-size: 12px;
	  margin-bottom: 0;
	  color: #919191;
	}
  
	.msg-header {
	  padding: .72rem 1.2rem;
	  color: #fff;
	  border-bottom: 0px solid #ededed;
	  background: #673ab7;
	  text-align: center;
	  border-top-left-radius: 15px;
	  border-top-right-radius: 15px;
  
	  .msg-header-title {
		font-size: 22px;
		margin-bottom: 0;
	  }
  
	  .msg-header-subtitle {
		font-size: 14px;
		margin-bottom: 0;
	  }
	}
  
	.msg-footer {
	  padding: .72rem 1.2rem;
	  color: #673ab7;
	  border-top: 0px solid #ededed;
	  background: transparent;
	  font-size: 14px;
	  font-weight: 500;
	  border-bottom-left-radius: .25rem;
	  border-bottom-right-radius: .25rem;
	}
  
	.notify {
	  width: 45px;
	  height: 45px;
	  line-height: 45px;
	  font-size: 22px;
	  text-align: center;
	  border-radius: 50%;
	  background-color: #f1f1f1;
	  margin-right: 15px;
	}
  }
  
  .dropdown-user-profile .dropdown-menu {
	i {
	  vertical-align: middle;
	  margin-right: 10px;
	}
  
	span {
	  vertical-align: middle;
	}
  }
  
  .dropdown-language .dropdown-menu {
	i {
	  vertical-align: middle;
	  margin-right: 10px;
	  font-size: 14px;
	}
  
	span {
	  vertical-align: middle;
	  font-size: 14px;
	}
  }
  
  .header-notifications-list, .header-message-list {
	position: relative;
	height: 420px;
  }
  
  .dashboard-social-list {
	position: relative;
	height: 230px;
  }
  
  .dashboard-top-countries {
	width: 100%;
	height: 322px;
  }