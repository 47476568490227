html {
	&.ColorLessIcons .sidebar-wrapper .sidebar-content {
	  .icon-color-1, .icon-color-2, .icon-color-3, .icon-color-4, .icon-color-5, .icon-color-6, .icon-color-7, .icon-color-8, .icon-color-9, .icon-color-10, .icon-color-11, .icon-color-12 {
		color: inherit;
	  }
	}
  
	&.dark-sidebar {
	  .sidebar-wrapper {
		background: #061238;
		border-right: 1px solid rgb(255 255 255 / 12%);
  
		.sidebar-header {
		  background: #061238;
		  border-right: 1px solid rgb(255 255 255 / 12%);
		  border-bottom: 1px solid rgb(255 255 255 / 12%);
  
		  .logo-text {
			color: #e7e9ec;
		  }
  
		  .toggle-btn {
			color: #e7e9ec;
  
			&:hover {
			  color: #fff;
			}
  
			&:active {
			  color: #fff;
			  background: rgb(255 255 255 / 25%);
			}
		  }
		}
	  }
  
	  .sidebar .navigation li {
		&.open > a {
		  color: #fff;
		  background: rgb(255 255 255 / 18%);
		}
  
		> a {
		  color: #9ea4aa;
		}
  
		&:hover > a, &.active > a {
		  color: #fff;
		  background: rgb(255 255 255 / 18%);
		}
  
		ul {
		  border: 1px solid #ffffff00;
		  background: rgb(255 255 255 / 8%);
  
		  li {
			&:hover > a, &.active > a {
			  color: #fff;
			  background: rgb(255 255 255 / 18%);
			}
		  }
		}
	  }
	}
  }